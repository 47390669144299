import { lighten } from 'polished';
import styled from 'styled-components';
import Theme from '@nehalist/gatsby-theme-nehalem/src/styles/theme';

const lightPurple = lighten(0.63, Theme.layout.primaryColor);

export const Author = styled.span`
	flex: 1 1 auto;
	font-size: 1.1rem;
	font-weight: bold;
	margin-left: 0.75em;
`;

export const CommentContainer = styled.div<{
	isAdmin: boolean;
	isReply: boolean;
}>`
	border: 1px solid ${Theme.layout.primaryColor};
	border-radius: 1em;
	margin-top: 2em;
	padding: 1em 1em 0 1em;

	background-color: ${props => (props.isAdmin ? lightPurple : 'white')};
	margin-left: ${props => (props.isReply ? '10%' : '0')};
	margin-right: ${props => (props.isReply ? '0' : '10%')};

	a:not(.anchor) {
		color: ${Theme.layout.linkColor};
		border-bottom: 2px ${Theme.layout.linkColor} solid;
	}
`;

export const Heading = styled.div`
	align-items: center;
	display: flex;
`;

export const PostDate = styled.time`
	cursor: help;
	font-size: 1.1rem;
	font-style: italic;
	white-space: nowrap;
`;
