import React, { FunctionComponent } from 'react';
import { useInView } from 'react-intersection-observer';
import { CommentsProps as NativeCommentsProps } from '@nehalist/gatsby-theme-nehalem/src/components/comments/index';
import { PostWithComments } from '../../../../models/PostWithComments';
import { PostComment } from '../../../../models/PostComment';
import Comment from '../../../../components/comment/index';
import CommentForm from '../../../../components/comment-form/index';
import { CommentsContainer, CommentsHeading } from './style';

function getTopLevelComment(
	allComments: PostComment[],
	childComment: PostComment,
): PostComment {
	if (!childComment.replyTo) return childComment;
	const parentComment = allComments.find(c => c._id === childComment.replyTo);
	if (!parentComment) return childComment;
	return getTopLevelComment(allComments, parentComment);
}

function sortComments(allComments: PostComment[]): PostComment[] {
	return allComments
		.map(c => ({
			...c,
			topCommentDate: new Date(getTopLevelComment(allComments, c).date),
		}))
		.sort((a, b) => {
			const parentDateDiff = +a.topCommentDate - +b.topCommentDate;
			if (parentDateDiff !== 0) return parentDateDiff;

			return +new Date(a.date) - +new Date(b.date);
		});
}

export interface CommentsProps extends NativeCommentsProps {
	post: PostWithComments;
}

const Comments: FunctionComponent<CommentsProps> = ({ post }) => {
	const [commentFormRef, isCommentFormInView] = useInView({
		triggerOnce: true,
	});

	return (
		<CommentsContainer>
			{post.comments && post.comments.length ? (
				<>
					<CommentsHeading>
						Comments on &quot;{post.frontmatter.title}&quot;
					</CommentsHeading>
					{sortComments(post.comments).map(comment => (
						<Comment comment={comment} key={comment.date} />
					))}
				</>
			) : null}

			<div ref={commentFormRef}>
				{!!isCommentFormInView && <CommentForm post={post} />}
			</div>
		</CommentsContainer>
	);
};

export default Comments;
