declare global {
	interface Window {
		dataLayer: Record<string, string>[];
	}
}

export function trackCommentError(errorMessage = ''): void {
	window.dataLayer = window.dataLayer || [];

	window.dataLayer.push({
		event: 'comment-error',
		commentError: errorMessage.toString(),
	});
}

export function trackCommentSuccess(): void {
	window.dataLayer = window.dataLayer || [];

	window.dataLayer.push({ event: 'comment-success' });
}
