import React, { FunctionComponent } from 'react';
import { PostComment } from '../../models/PostComment';
import { Author, CommentContainer, Heading, PostDate } from './style';

export interface CommentProps {
	comment: PostComment;
}

function getAuthorImage(authorEmail: string) {
	const icon = authorEmail ? 'identicon' : 'mp';
	return `https://www.gravatar.com/avatar/${authorEmail}?d=${icon}&s=55`;
}

const dateFormatter = new Intl.DateTimeFormat([], { dateStyle: 'medium' });
const dateTimeFormatter = new Intl.DateTimeFormat([], { dateStyle: 'medium', timeStyle: 'short' });

const Comment: FunctionComponent<CommentProps> = ({ comment }) => {
	const commentDate = new Date(comment.date);

	return (
		<CommentContainer isAdmin={comment.isAdmin} isReply={!!comment.replyTo}>
			<Heading>
				<img
					src={getAuthorImage(comment.email)}
					alt={`Avatar for ${comment.name}`}
					height="55"
					width="55"
				/>
				<Author>{comment.name}</Author>
				<PostDate dateTime={comment.date} title={dateTimeFormatter.format(commentDate)}>
					{dateFormatter.format(commentDate)}
				</PostDate>
			</Heading>
			<div
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{ __html: comment.childMarkdownRemark.html }}
			/>
		</CommentContainer>
	);
};

export default Comment;
