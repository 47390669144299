import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { PostWithComments } from '../../models/PostWithComments';
import submitComment from '../../actions/submit-comment';
import {
	trackCommentError,
	trackCommentSuccess,
} from '../../actions/track-event';
import Recaptcha from '../recaptcha';
import {
	ErrorMessage,
	Form,
	Heading,
	Label,
	Submit,
	SuccessMessage,
} from './style';

export interface CommentFormProps {
	post: PostWithComments;
}

function dirtyInput(event: React.FocusEvent | React.KeyboardEvent) {
	const el = event.target as Element;
	el.classList.add('dirty');
}

function resetForm(form: HTMLFormElement) {
	form.reset();
	[...form.elements].forEach(formElement => {
		formElement.classList.remove('dirty');
	});
}

type SubmitResult = {
	error?: string;
	isSuccess: boolean;
};

const CommentForm: FunctionComponent<CommentFormProps> = ({ post }) => {
	const [waitingText, setWaitingText] = useState('Loading...');
	const [submitResult, setSubmitResult] = useState<SubmitResult>(null);
	const form = useRef<HTMLFormElement>(null);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setSubmitResult(null);

		setWaitingText('Submitting...');
		const { error, isSuccess } = await submitComment(form.current);
		setWaitingText(null);

		if (isSuccess) {
			trackCommentSuccess();
			setSubmitResult({ isSuccess: true });
			resetForm(form.current);
		} else {
			trackCommentError(error);
			const msg = `An error occurred while saving your comment: ${error}.<br/><br/>Please try again.`;
			setSubmitResult({ isSuccess: false, error: msg });
		}
	};

	return (
		<Form ref={form} onSubmit={onSubmit}>
			<Heading>Add a comment</Heading>

			{!!submitResult && submitResult.isSuccess && (
				<SuccessMessage>
					Success! Your comment has been submitted for moderation.
				</SuccessMessage>
			)}
			{!!submitResult && !submitResult.isSuccess && (
				<ErrorMessage
					dangerouslySetInnerHTML={{ __html: submitResult.error }}
				/>
			)}

			<Label>
				<input
					name="fields[name]"
					type="text"
					required
					placeholder=" "
					maxLength={50}
					aria-describedby="name-help"
					aria-labelledby="name-label"
					onBlur={dirtyInput}
					onKeyDown={dirtyInput}
				/>
				<span className="field-name" id="name-label">
					Display Name*
				</span>
				<span className="help" id="name-help">
					Displayed with your comment
				</span>
			</Label>

			<Label>
				<input
					name="fields[email]"
					type="email"
					placeholder=" "
					aria-describedby="email-help"
					aria-labelledby="email-label"
					onBlur={dirtyInput}
					onKeyDown={dirtyInput}
				/>
				<span className="field-name" id="email-label">
					Email
				</span>
				<span className="help" id="email-help">
					Optional; saved as a hash for displaying your Gravatar
				</span>
			</Label>

			<Label>
				<textarea
					name="fields[message]"
					required
					placeholder=" "
					aria-describedby="message-help"
					aria-labelledby="message-label"
					onBlur={dirtyInput}
					onKeyDown={dirtyInput}
				/>
				<span className="field-name" id="message-label">
					Comment*
				</span>
				<span className="help" id="message-help">
					Supports{' '}
					<a
						href="https://guides.github.com/features/mastering-markdown/#examples"
						target="_blank"
						rel="noopener noreferrer"
					>
						GitHub Markdown syntax
					</a>
				</span>
			</Label>

			<input name="fields[url]" type="hidden" value={post.frontmatter.path} />

			<Submit type="submit" disabled={!!waitingText}>
				{waitingText || 'Add Comment'}
			</Submit>

			<Recaptcha
				onReady={useCallback(() => setWaitingText(null), [setWaitingText])}
			/>
		</Form>
	);
};

export default CommentForm;
