import { lighten } from 'polished';
import styled, { css } from 'styled-components';
import Theme from '@nehalist/gatsby-theme-nehalem/src/styles/theme';

const inputIndent = '10px';

export const Form = styled.form`
	margin: 40px auto 0;
	max-width: 600px;
`;

const MessageBox = css`
	border: 1px solid transparent;
	padding: 0.5em 1em;
`;

export const SuccessMessage = styled.div`
	${MessageBox}
	background-color: #d4edda;
	border-color: #c3e6cb;
	color: #155724;
`;

export const ErrorMessage = styled.div`
	${MessageBox}
	background-color: #f8d7da;
	border-color: #f5c6cb;
	color: #721c24;
`;

export const Heading = styled.h2`
	text-align: center;
`;

export const Label = styled.label`
	display: block;
	margin-top: 30px;
	position: relative;

	.field-name {
		cursor: text;

		position: absolute;
		left: ${inputIndent};
		top: 4px;

		transition: all 0.2s ease;
		transform-origin: 0 0;
	}

	textarea {
		height: 12em;
	}

	input,
	textarea {
		border: 2px solid lightgrey;
		border-radius: 0.5em;
		display: block;
		line-height: 1.5;
		padding: 5px 10px 5px ${inputIndent};
		width: 100%;

		&:focus,
		&:not(:placeholder-shown) {
			border-color: ${Theme.layout.primaryColor};
			outline: none;
		}

		&:focus + .field-name,
		&:not(:placeholder-shown) + .field-name {
			transform: translateX(-${inputIndent}) translateY(-31px) scale(0.9);
		}

		&.dirty:invalid {
			border-color: red;
		}
	}

	.help {
		font-size: 0.8em;

		a {
			color: ${Theme.layout.linkColor};
			border-bottom: 2px ${Theme.layout.linkColor} solid;
		}
	}
`;

export const Submit = styled.button`
	background-color: ${Theme.layout.primaryColor};
	border: 0;
	border-radius: 2em;
	color: white;
	cursor: pointer;
	display: block;
	font-size: 1.2em;
	margin: 20px auto 0 auto;
	padding: 15px 20px;

	&:disabled {
		cursor: not-allowed;
		background-color: ${lighten(0.3, Theme.layout.primaryColor)};
	}
`;
