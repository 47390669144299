import React, { FunctionComponent, useEffect } from 'react';
import reCaptcha from '../../constants/recaptcha';
import './style.scss';

let hasScriptLoadedOnce = false;

const ReCaptcha: FunctionComponent<{ onReady: () => void }> = ({ onReady }) => {
	useEffect(() => {
		if (hasScriptLoadedOnce) {
			onReady();
			return undefined;
		}

		const script = document.createElement('script');
		script.src = `https://www.google.com/recaptcha/api.js?render=${reCaptcha.siteKey}`;
		script.onload = () => grecaptcha.ready(onReady);
		document.body.appendChild(script);

		return () => {
			hasScriptLoadedOnce = true;
		};
	}, [onReady]);

	return (
		<>
			<p className="grecaptcha-text">
				This site is protected by reCAPTCHA and the Google{' '}
				<a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
				<a href="https://policies.google.com/terms">Terms of Service</a> apply.
			</p>
			<input
				name="options[reCaptcha][siteKey]"
				type="hidden"
				value={reCaptcha.siteKey}
			/>
			<input
				name="options[reCaptcha][secret]"
				type="hidden"
				value={reCaptcha.secret}
			/>
		</>
	);
};

export default ReCaptcha;
