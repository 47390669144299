import { graphql } from 'gatsby';
import PostTemplate from '@nehalist/gatsby-theme-nehalem/src/templates/post';

export default PostTemplate;

export const query = graphql`
	query PostTemplate($postId: String!, $primaryTag: String!) {
		post: markdownRemark(id: { eq: $postId }) {
			headings {
				depth
			}
			frontmatter {
				title
				path
				tags
				excerpt
				created
				createdPretty: created(formatString: "DD MMMM, YYYY")
				updated
				updatedPretty: updated(formatString: "DD MMMM, YYYY")
				featuredImage {
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
					}
				}
			}
			html
			comments {
				_id
				date
				email
				isAdmin
				name
				replyTo
				childMarkdownRemark {
					html
				}
			}
		}
		primaryTag: tags(name: { eq: $primaryTag }) {
			name
			color
		}
	}
`;
