import reCaptcha from '../constants/recaptcha';

const commentApiUrl = 'https://comment-gateway-7zk6jpxx.uc.gateway.dev/comment/';

async function getReCaptchaToken() {
	const { action, siteKey } = reCaptcha;
	return grecaptcha.execute(siteKey, { action });
}

function serializeForm(data: HTMLFormControlsCollection): URLSearchParams {
	type FormField = HTMLInputElement | HTMLTextAreaElement;

	return [...data]
		.filter((formField: FormField) => !!formField.name)
		.reduce((accumulator, { name, value }: FormField) => {
			// Trim and lowercase the email address before Staticman hashes it
			const normalizedValue =
				name === 'fields[email]' ? (value || '').trim().toLowerCase() : value;
			accumulator.append(name, normalizedValue);
			return accumulator;
		}, new URLSearchParams());
}

type CommentResult = {
	error?: string;
	isSuccess?: boolean;
};

export default async function submitComment(
	form: HTMLFormElement,
): Promise<CommentResult> {
	const formData = serializeForm(form.elements);

	try {
		formData.append('g-recaptcha-response', await getReCaptchaToken());
	} catch (ex) {
		return { error: ex };
	}

	try {
		const response = await fetch(commentApiUrl, {
			method: 'POST',
			body: formData,
		});

		const { errorCode, message, success } = await response.json();

		return {
			isSuccess: response.ok && success,
			error: message || errorCode,
		};
	} catch (ex) {
		return { error: ex };
	}
}
